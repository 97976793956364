import logo from '../logo/bqt_logo.png';

const Header = () => {
  return (
    <footer className="footer">
      <hr className="footer-divider" />
      <p className="footer-logo">
        {/* <img
          src="../../"
          alt="Barquecon Technologies Pvt Ltd Logo"
          className="footer-logo-img"
        /> */}
         <img  className="footer-logo-img" src={logo} alt="Barquecon Technologies Pvt Ltd Logo" />

      </p>
      <p className="footer-text">
        Copyright © 2024. All rights reserved to Barquecon Technologies Pvt Ltd.
      </p>
    </footer>
  );
};

export default Header;
