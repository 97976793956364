import {
    APP_CREATE_FAIL,
    APP_CREATE_REQUEST,
    APP_CREATE_SUCCESS,
    APP_DELETE_FAIL,
    APP_DELETE_REQUEST,
    APP_DELETE_SUCCESS,
    APP_EDIT_FAIL,
    APP_EDIT_REQUEST,
    APP_EDIT_SUCCESS,
    APP_LIST_FAIL,
    APP_LIST_REQUEST,
    APP_LIST_SUCCESS,
    APP_UPDATE_FAIL,
    APP_UPDATE_REQUEST,
    APP_UPDATE_SUCCESS,
  } from "../constants/AppConstants";
  import axios from "axios";
  import { logout } from "./userActions";
  import { toast } from "react-toastify";
  import {ToastObjects} from "./toastObject";
  
  export const listApps = (pageNum,appsPerPage,sortBy,searchText, role, filterBy, activeTab, from, organizationIds) => async (dispatch, getState) => {
    try {
      dispatch({ type: APP_LIST_REQUEST });
      let url = "";

      if(role === 'Super_Admin'){
        if(from !== 'organization'){
          url = 'admin/apps';
        } else {
          url = `users/apps/${organizationIds}`;
        }
      } else {
        url = `users/apps/${organizationIds}`;
      }
  
      let responseData = await axios.get(`${url}?page=${pageNum}&limit=${appsPerPage}&sortBy=${sortBy}&searchText=${searchText}&filterByOs=${filterBy}&status=${activeTab}`);
      const data = responseData.data;
  
      data['sortBy'] = sortBy;
      data['filterByOs'] = filterBy;
      data['searchText'] = searchText;
      data['numOfPages'] = Math.ceil(data.totalCount / appsPerPage);
  
      dispatch({ type: APP_LIST_SUCCESS, payload: data });
    } catch (error) {
      const message = error.message;
      if (message === "Authentication failed: Invalid token." || message === "Unauthorized User" || message === "Request failed with status code 401") {
        dispatch(logout());
      }
      if(message === "Request failed with status code 401"){
        dispatch(logout());
      }else{
        toast.error(message);
      }
      dispatch({
        type: APP_LIST_FAIL,
        payload: message,
      });
    }
  };
  
  // Delete App
  export const deleteApp = (id) => async (dispatch, getState) => {
  
    try {
      dispatch({ type: APP_DELETE_REQUEST });
  
      const response = await axios.delete(`users/app/${id}`);
  
      const responseData = response.data;
  
      if (!responseData.success) {
          toast.error(responseData.message, ToastObjects);  
        }else{
          toast.success(responseData.message, ToastObjects);  
          dispatch({ type: APP_DELETE_SUCCESS });
      }
    } catch (error) {
      const message = error.message;
    if (message === "Authentication failed: Invalid token." || message === "Unauthorized User" || message === "Request failed with status code 401") {
      dispatch(logout());
    }
      if(message === "Request failed with status code 401"){
        dispatch(logout());
      }else{
        toast.error(message, ToastObjects);
      }
      dispatch({
        type: APP_DELETE_FAIL,
        payload: message,
      });
    }
  };
  
  // Create App
  export const createApp = (reqData) => async (dispatch, getState) => {
      
      try {
        dispatch({ type: APP_CREATE_REQUEST });
  
        const response = await axios.post(
          `users/app`,
          reqData
        );
  
        const responseData = response.data;
        if (!responseData.status) {
          toast.error(responseData.message, ToastObjects);  
        }else{
          toast.success(responseData.message, ToastObjects);        
          dispatch({ type: APP_CREATE_SUCCESS, payload: responseData.data });  
        }
        
        
      } catch (error) {
        const message = error.message;
      if (message === "Authentication failed: Invalid token." || message === "Unauthorized User" || message === "Request failed with status code 401") {
        dispatch(logout());
      }
        if(message === "Request failed with status code 401"){
          dispatch(logout());
        }else{
          toast.error(message, ToastObjects);
        }
        dispatch({
          type: APP_CREATE_FAIL,
          payload: message,
        });
      }
    };
  
  // Edit App
  export const editApp = (id) => async (dispatch) => {
    try {
      dispatch({ type: APP_EDIT_REQUEST });
      const { data } = await axios.get(`users/app/${id}`);
      dispatch({ type: APP_EDIT_SUCCESS, payload: data });
    } catch (error) {
      const message =error.message;
      if (message === "Authentication failed: Invalid token."  || message ==="Unauthorized User" || message === "Request failed with status code 401") {
        dispatch(logout());
      }
      if(message === "Request failed with status code 401"){
        toast.error("Teken Expired")
      }else{
        toast.error(message, ToastObjects);
      }
      dispatch({
        type: APP_EDIT_FAIL,
        payload: message,
      });
    }
  };
  
  // Update App
  export const updateApp = (reqData) => async (dispatch, getState) => {
  
    try {
      dispatch({ type: APP_UPDATE_REQUEST });
      const { data } = await axios.put(
        `users/app`,
        reqData      
      );
  
      dispatch({ type: APP_UPDATE_SUCCESS, payload: data });
      dispatch({ type: APP_EDIT_SUCCESS, payload: data });
    } catch (error) {
      const message = error.message;
      if (message === "Authentication failed: Invalid token." || message=== "Unauthorized User" || message === "Request failed with status code 401") {
        dispatch(logout());
      }
  if(message === "Request failed with status code 401"){
    dispatch(logout());
  }else{
    toast.error(message, ToastObjects);
  }
      dispatch({
        type: APP_UPDATE_FAIL,
        payload: message,
      });
    }
  };

  export const listOrganizationApps = (pageNum,appsPerPage,sortBy,searchText, role, filterBy, activeTab) => async (dispatch, getState) => {
    try {
      dispatch({ type: APP_LIST_REQUEST });
      let url = ""

      if(role === 'Super_Admin'){
        url = 'admin/apps'
      } else {
        url = 'users/apps'
      }

      let responseData = await axios.get(`${url}?page=${pageNum}&limit=${appsPerPage}&sortBy=${sortBy}&searchText=${searchText}&filterByOs=${filterBy}&status=${activeTab}`);
      const data = responseData.data;

      data['sortBy'] = sortBy;
      data['filterByOs'] = filterBy;
      data['searchText'] = searchText;
      data['numOfPages'] = Math.ceil(data.totalCount / appsPerPage);

  
      dispatch({ type: APP_LIST_SUCCESS, payload: data });
    } catch (error) {
      const message = error.message;
      if (message === "Authentication failed: Invalid token." || message === "Unauthorized User" || message === "Request failed with status code 401") {
        dispatch(logout());
      }
      if(message === "Request failed with status code 401"){
        dispatch(logout());
      }else{
        toast.error(message);
      }
      dispatch({
        type: APP_LIST_FAIL,
        payload: message,
      });
    }
  };
  