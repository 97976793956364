import {useState, useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import Header from '../../Header';
import Sidebar from '../../Sidebar';
import Footer from '../../Footer';
import {editApp} from '../../../redux/actions/AppActions'; 
import {updateApp} from '../../../redux/actions/AppActions'; 
import { APP_UPDATE_RESET } from "../../../redux/constants/AppConstants";
import { toast } from "react-toastify";
import {ToastObjects} from '../../../redux/actions/toastObject'; 
import { UserDropdown } from '../../../redux/actions/userActions';
import { useHistory } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';
import { FaApple, FaAndroid } from 'react-icons/fa'; 
import '../main.css'
import { Modal, Button } from "react-bootstrap"; 

const EditApp = ({match}) => {	
  const appId = match.params.id;
  const history = useHistory();
  const dispatch = useDispatch();

  const appList = useSelector((state) => state.appList);
  const organization = useSelector((state)=>state?.organizationDetails?.organization);
  const userRole = organization?.user_role;
  const { apps } = appList; 
  const users = useSelector((state) => state.userDropdown.users);
  const role = useSelector((state) => state.userLogin.userInfo.data.role);
  const organizationId = organization?.organization_id;
  const appEdit = useSelector((state) => state.appEdit);
  const { app } = appEdit;
  const appUpdate = useSelector((state) => state.appUpdate);
  const { success: successUpdate } = appUpdate;

  const [submitted, setSubmitted] = useState(false);  
  const [isLogo, setIsLogo] = useState(false);
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false); 
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [formState,setFormState] = useState({
	values:{
		isIos: 0,
		isAndriod: 0,
		bundleName: '',
		packageName: '',
		slack_webhook_url: '',
		isSlack: false
	}       
  });
  const [status, setStatus] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [ymlType, setYmlType] = useState("");

  const envirionment = process.env.REACT_APP_ENVIRIONMENT;
  const BASE_URL = process.env.BACKEND_URL;

  const prod_yml_text = `
  name: Build and Upload for Android
  
  on:
	push:
	  branches:
		- main
  
  jobs:
	build-and-upload:
	  runs-on: ubuntu-latest
  
	  steps:
		# Step 1: Checkout the Repository
		- name: Checkout Code
		  uses: actions/checkout@v3
  
		# Step 2: Set Up Java (Required for Android Builds)
		- name: Setup Java
		  uses: actions/setup-java@v3
		  with:
			distribution: 'zulu'
			java-version: '17'
   
		# Step 3: Set Up Android Environment
		- name: Setup Android SDK
		  uses: android-actions/setup-android@v2
		  with:
			api-level: 33
			build-tools: '33.0.2'
			target: 'android-33'
  
		# Step 5: Make gradlew Executable
		- name: Make gradlew Executable
		  run: chmod +x ./gradlew
  
		# Step 6: Build the Android Project
		- name: Build APK
		  run: ./gradlew assembleDebug

		# Step 7: Upload the Build
      - name: Upload Build
        env:
          BASE_URL: ${BASE_URL}
          AUTH_TOKEN: ${app.app_secret_key}
        run: |
          curl -X POST "${BASE_URL}/api/v1/app/${app.id}/upload/build" \
          -H "Authorization: Bearer $AUTH_TOKEN" \
          -H "Content-Type: multipart/form-data" \
          -F "os=Android" \
          -F "environment="Production" \
          -F "file=@app/build/outputs/apk/debug/app-debug.apk"
	`;

    const stage_yml_text = `
  name: Build and Upload for Android
  
  on:
	push:
	  branches:
		- staging
  
  jobs:
	build-and-upload:
	  runs-on: ubuntu-latest
  
	  steps:
		# Step 1: Checkout the Repository
		- name: Checkout Code
		  uses: actions/checkout@v3
  
		# Step 2: Set Up Java (Required for Android Builds)
		- name: Setup Java
		  uses: actions/setup-java@v3
		  with:
			distribution: 'zulu'
			java-version: '17'
   
		# Step 3: Set Up Android Environment
		- name: Setup Android SDK
		  uses: android-actions/setup-android@v2
		  with:
			api-level: 33
			build-tools: '33.0.2'
			target: 'android-33'
  
		# Step 5: Make gradlew Executable
		- name: Make gradlew Executable
		  run: chmod +x ./gradlew
  
		# Step 6: Build the Android Project
		- name: Build APK
		  run: ./gradlew assembleDebug

		# Step 7: Upload the Build
      - name: Upload Build
        env:
          BASE_URL: ${BASE_URL}
          AUTH_TOKEN: ${app.app_secret_key}
        run: |
          curl -X POST "${BASE_URL}/api/v1/app/${app.id}/upload/build" \
          -H "Authorization: Bearer $AUTH_TOKEN" \
          -H "Content-Type: multipart/form-data" \
          -F "os=Android" \
          -F "environment="Staging" \
          -F "file=@app/build/outputs/apk/debug/app-debug.apk"
	`;

  useEffect(() => {
  	setFormState({values:{}})
  	if (successUpdate) {
      dispatch({ type: APP_UPDATE_RESET });
      toast.success("App updated successfully", ToastObjects);
    } else {
      if (!app.name || app.id !== Number(appId)) {
        dispatch(editApp(appId));    
		
      } else {
        setFormState({values:app})
		setSelectedUserIds(app.appAccess)
		setUserIds(app.appAccess)
      }
    }

	dispatch(UserDropdown('app',appId, organizationId));    
  }, [app, dispatch, appId, organizationId]);
  

    const handleUserChange = (event) => {
	    const selectedUserId = parseInt(event.target.value);
	    if (!selectedUserIds.includes(selectedUserId)) {
	       setSelectedUserIds([...selectedUserIds, selectedUserId]);
	    }
    };

  const removeUser = (userIdToRemove) => {
	setSelectedUserIds(selectedUserIds.filter(userId => userId !== userIdToRemove));
  };

  const filteredOptions = users.filter(option => !selectedUserIds.includes(option.id));
  const selectedUsers = users.filter(option => selectedUserIds.includes(option.id));
  const handleChange = (event) => {
        setFormState(formState =>({
          ...formState,
          values:{
            ...formState.values,
            [event.target.name]:
            event.target.type === 'checkbox'
                ? event.target.checked
                : event.target.value
          }
          
        }));
      }

	  const handleUploadChange = (event) => {
        event.preventDefault();
        setLogo(event.target.files[0])
      };
	  const getValidationError = (name, value) => {
		switch (name) {
		  case 'logo':
			return !value ? 'Logo is required' : '';
		  case 'ios' || 'android':
			return !(formState.values.isIos || formState.values.isAndriod) ? 'At least one platform must be selected' : '';
		  default:
			return '';
		}
	  };

    const handleSubmit = () => {
        setSubmitted(true); 
        const { name, description, isAndriod, isIos, packageName, bundleName, slack_webhook_url, isSlack } = formState.values;
		const removeIds = userIds.filter((element) => !selectedUserIds.includes(element));

        if (name && description) {
			if ((isIos || isAndriod)) {
				let _errorFlag = false, _errorMessage = "";
				if(isAndriod === 1 && packageName.length === 0 && isIos === 0){
					_errorFlag = true;
					_errorMessage = "Please enter App package name.";
				} else if(isIos === 1 && bundleName.length === 0 && isAndriod === 0){
					_errorFlag = true;
					_errorMessage = "Please enter App Bundle name.";
				} else if((isAndriod === 1 && isIos === 1)){
					
					if(bundleName.length === 0 && packageName.length === 0){
						_errorFlag = true;
					  _errorMessage = "Please enter App Bundle name and App package name.";
					} else if(packageName.length === 0){
						_errorFlag = true;
                      _errorMessage = "Please enter App package name.";
					} else if(bundleName.length === 0){
						_errorFlag = true;
                      _errorMessage = "Please enter App Bundle name.";
					}
				}

				if(_errorFlag === false){
					console.log('Form is valid');
					setLoading(true)
					let payload = new FormData();
	
					payload.set("id", appId);
					payload.set("name", name);
					payload.set("description", description);
					payload.set("isAndriod", isAndriod);
					payload.set("isIos", isIos);
					payload.set("removeIds", removeIds);
					payload.set("selectedUserIds", selectedUserIds);
					payload.set("packageName", packageName);
					payload.set("bundleName", bundleName);
					payload.set("slack_webhook_url", slack_webhook_url);
					payload.set("isSlack", isSlack);
	
					if(logo){
					  payload.set("oldLogoId", app.logo_id);
					  payload.append("logo", logo);
					}
	
					if(app.app_status === 'Requested'){
						payload.set("app_status", status);
					}
					
					dispatch(updateApp(payload)).then((res)=>{
						setSubmitted(false);
						setFormState(formState => ({
							...formState,
							errors: {
							  ...formState.errors,
							  ios: getValidationError('ios', formState.values.ios),
							  android: getValidationError('android', formState.values.android),
							}
						  }));
						setLoading(false)
						navigateToAppList();
					});
				} else {
                   alert(_errorMessage);
				}
			} else {
				console.log('Form has errors');
				alert("At least one os will be selected")
			}
        }
    }

	const navigateToAppList = () => {
		history.push('/apps');
	};
	
	const handleStatus = (e) => {
		setStatus(e.target.value)
	}

	const appStatuses = apps.map((app) => app.app_status);
    const isAnyRequested = app?.app_status === "Requested" ? true : false;

	const togglePasswordVisibility = () => {
		setPasswordVisible(!passwordVisible);
	 };

	 
	const handleToggleChange = () => {
		console.log(!formState.values.isSlack,"00",formState.values.isSlack)
		setFormState({
		  ...formState,
		  values: {
			...formState.values,
			isSlack: !formState.values.isSlack
		  }
		});
	};

	const downloadYaml = (fileName, content) => {
		const blob = new Blob([content], { type: "text/yaml" });
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = fileName;
		link.click();
		URL.revokeObjectURL(link.href);
	  };

	return(
		<>
		    <div className="container-scroller">
				<Header/>
				<div className="container-fluid page-body-wrapper">
				   <Sidebar/>
				   <div className="main-panel">
				        <div className="content-wrapper">
					        <div className="row">
				               <div className="col-12 grid-margin">
				                  <div className="card">
				                     <div className="card-body">

										{isAnyRequested === false ?
										<>
										<div className="header headingWrap">
										<Link to="/apps" className ="backarrowcolor">
											<i className="bi bi-arrow-left-circle-fill backIcon"></i>
										</Link>
										<h4 className="card-title">Edit App Details</h4>
										</div>	
										<form className="form-sample formStyle mt-4" onSubmit={handleSubmit}>
				                           <p className="card-description">				                              
				                           </p>
				                           <div className="row">
				                              <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">App Name
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="">
									                        <input type="text" className={'form-control form-control-lg' + (submitted && !formState.values.name ? ' is-invalid' : '')} 
					                                        name="name"  
					                                        onChange={handleChange}
					                                        value={formState.values.name || ''}
					                                        />
					                                        {submitted && !formState.values.name &&
					                                            <div className="inline-errormsg">App name is required</div>
					                                        }
				                                    </div>
				                                 </div>
				                              </div>

											  <div className="col-md-6">
											  	<div className="form-group">
				                                    <label className="col-form-label mb-0">App Logo
													<span style={{ color: 'red' }}>*</span>
													</label>

													{formState.values.logo_url && isLogo === false ? (
                                                        <div className="">
															<img alt="logo" src={formState.values.logo_url} style={{height:"80px", width:"80px", paddingRight:"20px"}}></img>
                                                            {/* <a href={formState.values.logo_url} download={getFileNameFromUrl(formState.values.logo_url)} className="btn btn-link">
                                                               {getFileNameFromUrl(formState.values.logo_url)} <i className="fa fa-download  pl-10"></i>
                                                            </a> */}

													        <button className="btn btn-secondary me-1" onClick={()=> setIsLogo(true)}>Change Logo</button>
                                                        </div>
											        )
											        :
											
				                                    <div className="col-sm-9">
				                                       <input type="file" className={'form-control form-control-lg' + (submitted && !logo ? ' is-invalid' : '')} 
					                                        name="file" 
                                                            single
                                                            accept="image/*"
					                                        onChange={handleUploadChange}
					                                        />
					                                        {submitted && !logo &&
					                                            <div className="inline-errormsg">Logo is required</div>
					                                        }
				                                    </div>
}
				                                 </div>
				                              </div>

											  <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">Description
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="">
				                                       <textarea rows={5} cols={5} className={'form-control form-control-lg' + (submitted && !formState.values.title ? ' is-invalid' : '')} 
					                                        name="description" 
					                                        onChange={handleChange}
					                                        value={formState.values.description || ''}
					                                        />
					                                        {submitted && !formState.values.description &&
					                                            <div className="inline-errormsg">Description is required</div>
					                                        }
				                                    </div>
				                                 </div>
				                              </div>

											  <div className="col-md-6">
											  <div className="form-group">
                                                <label className="col-form-label mb-0"> OS 
												  <span style={{ color: 'red' }}>*</span>
                                                </label>
												<div className="" style={{"paddingLeft":"26px"}}>
												   <div className="form-check" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="isIos"
                                                          onChange={handleChange}
                                                          checked={formState.values.isIos}
                                                          style={{ marginRight: '10px' }}
                                                        />
                                                        <label className="form-check-label" style={{ display: 'flex', alignItems: 'center', marginRight: '35px', marginTop: '10px' }}>
                                                           <FaApple style={{ marginRight: '5px' }} /> iOS
                                                        </label>
                                                        {(formState.values.isIos === 1 || formState.values.isIos === true) && (
                                                            <input
                                                               type="text"
                                                               className="form-control"
															   name="bundleName"
                                                               placeholder="Enter Bundle ID"
                                                               value={formState.values.bundleName}
                                                               onChange={handleChange}
                                                               style={{ marginLeft: '10px' }}
                                                            />
                                                        )}
                                                    </div>
	                                                <div className="form-check" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <input
                                                           type="checkbox"
                                                           className="form-check-input"
                                                           name="isAndriod"
                                                           onChange={handleChange}
                                                           checked={formState.values.isAndriod}
                                                           style={{ marginRight: '10px' }}
                                                        />
                                                        <label className="form-check-label" style={{ display: 'flex', alignItems: 'center', marginRight: '10px', marginTop: '10px' }}>
                                                            <FaAndroid style={{ marginRight: '5px' }} /> Android
                                                        </label>
                                                        {(formState.values.isAndriod === 1 || formState.values.isAndriod === true) && (
                                                          <input
                                                            type="text"
                                                            className="form-control"
															name="packageName"
                                                            placeholder="Enter Package Name"
                                                            value={formState.values.packageName}
                                                            onChange={handleChange}
                                                            style={{ marginLeft: '10px' }}
                                                          />
                                                        )}
                                                    </div>
                                                    {submitted && !(formState.values.isIos || formState.values.isAndriod) && (
                                                        <div className="invalid-feedback d-block">At least one platform must be selected</div>
                                                    )}
                                                </div>
                                             </div>
				                              </div>

											  	<div className="col-md-12">
	                                                <div className="form-group">
                                                        <label className="col-form-label">Access To </label>
                                                        <div className="">
															<div className="custom-select-container">
															<div className="selected-roles">
                                                                {selectedUsers.map((user) => (
                                                                    <span key={user.id} className="selected-role">
                                                                      {user.name}
                                                                      <button type="button" className="remove-role-btn" onClick={() => removeUser(user.id)}>x</button>
                                                                    </span>
                                                                ))}

                                                               <select className="form-control role-select" onChange={handleUserChange} value="">
                                                                    <option value="" disabled>Select User</option>
                                                                    {filteredOptions.map((user) => (
                                                                       <option key={user.id} value={user.id}>{user.name}</option>
                                                                    ))}
                                                                </select> 
																{/* <Autocomplete
																className='SelectAccessTo'
                                                                   options={filteredOptions}
                                                                   getOptionLabel={(option) => option.name}
                                                                   onChange={handleUserChange}
                                                                   renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
                                                                   isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                   value={null}
                                                                   /> */}
                                                            </div>
															</div>
	                                                       
                                                        </div>
                                                    </div>
													
                                                </div>

				                           </div>

											{/* {userRole === 'Admin' ?
											  <div className="row">
				                              	<div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">Status
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="">
														<select className="form-select form-control" aria-label="Envirionment" value={status} onChange={handleStatus}>
															<option value="">Select Status</option>
															{app.app_status === 'Requested'?
															<>
														  	  <option value="Active">Approve</option>
															  <option value="Rejected">Reject</option>
															</>
															
															: app.app_status === 'Active' ?
															<>
														  	  <option value="Inactive">Inactive</option>
															</>
															: app.app_status === 'Inactive' ?
															<>
														  	  <option value="Active">Active</option>
															</>
															: null }
														</select>
				                                    </div>
				                                 </div>
				                              </div>				                              
				                              </div>
											: null} */}
				                           
				                        </form>
										</>:
										<><div className="header headingWrap ">
										<Link to="/apps" className ="backarrowcolor">
										<i className="bi bi-arrow-left-circle-fill backIcon"></i>
										</Link>
										<h4 className="card-title">App Details</h4>
										</div>
										<form className="form-sample formStyle mt-4" onSubmit={handleSubmit}>
				                           <p className="card-description">				                              
				                           </p>
				                           <div className="row">
				                              <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">App Name
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="">
									                        <input type="text" className={'form-control form-control-lg' + (submitted && !formState.values.name ? ' is-invalid' : '')} 
					                                        name="name"  
															readOnly={app?.app_status === 'Requested' ? true : false}                              
					                                        onChange={handleChange}
					                                        value={formState.values.name || ''}
					                                        />
					                                        {submitted && !formState.values.name &&
					                                            <div className="inline-errormsg">App name is required</div>
					                                        }
				                                    </div>
				                                 </div>
				                              </div>

											  <div className="col-md-6">
													<div className="form-group">
														<label className="col-form-label mb-0">App Logo
														<span style={{ color: 'red' }}>*</span>
														</label>

														{formState.values.logo_url && isLogo === false ? (
															<div className="">
																<img alt="logo" src={formState.values.logo_url} style={{height:"80px", width:"80px", paddingRight:"20px"}}></img>
																{/* <a href={formState.values.logo_url} download={getFileNameFromUrl(formState.values.logo_url)} className="btn btn-link">
																{getFileNameFromUrl(formState.values.logo_url)} <i className="fa fa-download  pl-10"></i>
																</a> */}

																<button className="btn btn-secondary me-1" disabled onClick={()=> setIsLogo(true)}>Change Logo</button>
															</div>
														)
														:
												
														<div className="col-sm-9">
														<input type="file" className={'form-control form-control-lg' + (submitted && !logo ? ' is-invalid' : '')} 
																name="file" 
																readOnly={app?.app_status === 'Requested' ? true : false}                              
																single
																accept="image/*"
																onChange={handleUploadChange}
																/>
																{submitted && !logo &&
																	<div className="inline-errormsg">Logo is required</div>
																}
														</div>
	}
													</div>
												</div>

											  <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">Description
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="">
				                                       <textarea rows={5} cols={5} className={'form-control form-control-lg' + (submitted && !formState.values.title ? ' is-invalid' : '')} 
					                                        name="description" 
															readOnly={app?.app_status === 'Requested' ? true : false}                                                      
					                                        onChange={handleChange}
					                                        value={formState.values.description || ''}
					                                        />
					                                        {submitted && !formState.values.description &&
					                                            <div className="inline-errormsg">Description is required</div>
					                                        }
				                                    </div>
				                                 </div>
				                              </div>	

												<div className="col-md-6">
													<div className="form-group">
													<label className="col-form-label"> OS 
													<span style={{ color: 'red' }}>*</span>
													</label>
													<div className="" style={{"paddingLeft":"26px"}}>
													<div className="form-check" style={{ display: 'flex', alignItems: 'center' }}>
															<input
															type="checkbox"
															className="form-check-input"
															name="isIos"
															onChange={handleChange}
															checked={formState.values.isIos}
															style={{ marginRight: '10px' }}
															/>
															<label className="form-check-label" style={{ display: 'flex', alignItems: 'center', marginRight: '35px', marginTop: '10px' }}>
															<FaApple style={{ marginRight: '5px' }} /> iOS
															</label>
															{formState.values.isIos === 1 && (
																<input
																type="text"
																readOnly={app?.app_status === 'Requested' ? true : false}                              
																className="form-control"
																name="bundleName"
																placeholder="Enter Bundle ID"
																value={formState.values.bundleName}
																onChange={handleChange}
																style={{ marginLeft: '10px' }}
																/>
															)}
														</div>
														<div className="form-check" style={{ display: 'flex', alignItems: 'center' }}>
															<input
															type="checkbox"
															readOnly={app?.app_status === 'Requested' ? true : false}                              
															className="form-check-input"
															name="isAndriod"
															onChange={handleChange}
															checked={formState.values.isAndriod}
															style={{ marginRight: '10px' }}
															/>
															<label className="form-check-label" style={{ display: 'flex', alignItems: 'center', marginRight: '10px', marginTop: '10px' }}>
																<FaAndroid style={{ marginRight: '5px' }} /> Android
															</label>
															{formState.values.isAndriod === 1 && (
															<input
																type="text"
																readOnly={app?.app_status === 'Requested' ? true : false}                              
																className="form-control"
																name="packageName"
																placeholder="Enter Package Name"
																value={formState.values.packageName}
																onChange={handleChange}
																style={{ marginLeft: '10px' }}
															/>
															)}
														</div>
														{submitted && !(formState.values.isIos || formState.values.isAndriod) && (
															<div className="invalid-feedback d-block">At least one platform must be selected</div>
														)}
													</div>
													</div>
												</div>	

												

												<div className="col-md-12">
	                                                <div className="form-group">
                                                        <label className="col-form-label">Access To </label>
                                                        <div className="" >
															<div className="custom-select-container">
															<div className="selected-roles">
                                                                {selectedUsers.map((user) => (
                                                                    <span key={user.id} className="selected-role">
                                                                      {user.name}
                                                                      <button type="button" className="remove-role-btn" onClick={() => removeUser(user.id)}>x</button>
                                                                    </span>
                                                                ))}

                                                                <select 
																    className="form-control role-select" 
																    disabled={app?.app_status === 'Requested' ? true : false}                              
																    onChange={handleUserChange} 
																	value=""
																>
                                                                    <option value="" disabled>Select User</option>
                                                                    {filteredOptions.map((user) => (
                                                                       <option key={user.id} value={user.id}>{user.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
															</div>
	                                                       
                                                        </div>
                                                    </div>
													
                                                </div>
				                           </div>


											{userRole === 'Admin' ?
											  <>
											  <div className="row">
				                              <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">Status
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="">
														<select className="form-select form-control" aria-label="Envirionment" value={status} onChange={handleStatus}>
															<option value="">Select Status</option>
															{app.app_status === 'Requested'?
															<>
														  	  <option value="Active">Approve</option>
															  <option value="Rejected">Reject</option>
															</>
															
															: app.app_status === 'Active' ?
															<>
														  	  <option value="Inactive">Inactive</option>
															</>
															: app.app_status === 'Inactive' ?
															<>
														  	  <option value="Active">Active</option>
															</>
															: null }
														</select>
				                                    </div>
				                                 </div>
				                              </div>				                              
				                              </div>
											 
											  </>
											: null}
										
				                           
				                        </form>
										</>
										}
				                     </div>
				                  </div>

                                  	{envirionment === 'STAGING' && isAnyRequested === false ?
								    	<div className="card" style={{"marginTop": "10px"}}>
											<div className="card-body">
												<h4 className="card-title">App Secrete</h4>
												<div className="row">
													<div className="col-md-6">
											   			<div className="form-group">
												  			<label className="col-form-label">App Id </label>
												  			<div className="">
													  			<input 
																	type="text" 
																	className={'form-control form-control-lg' + (submitted && !formState.values.id ? ' is-invalid' : '')} 
														  			name="name"  
														  			readOnly                              
														  			onChange={handleChange}
														  			value={formState.values.id || ''}
													  			/>
												  			</div>
											   			</div>
													</div>	
													<div className="col-md-6">
											   			<div className="form-group">
												  			<label className="col-form-label"> Secrete Key </label>
												  			<div className="">
													  			<input  
														  			type={passwordVisible ? 'text' : 'password'}
														  			className={'form-control form-control-lg'} 
														  			name="name"  
														  			readOnly                              
														  			onChange={handleChange}
														  			value={app.app_secret_key || ''}
													  			/>
													  			<i
														  			onClick={togglePasswordVisibility}
														  			className={passwordVisible ? 'fa fa-eye-slash position-absolute end-0 me-2' : 'fa fa-eye position-absolute end-0 me-2'}
														  			style={{ top: '50%', transform: 'translateY(-50%)', cursor: 'pointer', padding: "30px", paddingTop: "60px" }}
													  			></i>
												  			</div>
											   			</div>
													</div>				                              
												</div>
											</div>
										</div>
								  	: null}
								  
								    {isAnyRequested === false ?
										<div className="card" style={{"marginTop": "10px"}}>
											<div className="card-body">
												<h4 className="card-title">Notifications</h4>
												<div className="row">
											  		<div className="col-md-12">
													  	<div className="form-group d-flex align-items-center">
																<label className="col-form-label mr-3" style={{ minWidth: '40px' }}>Slack</label>
																<label className="switch mr-5" style={{ minWidth: '60px' }}>
														  			<input 
																		type="checkbox"
																		checked={formState.values.isSlack}
																		onChange={() => handleToggleChange()}
														  			/>
														  			<span className="slider round"></span>
																</label>

																{formState?.values?.isSlack === 1 || formState?.values?.isSlack === true ?
														 			<>
															  			<label className="col-form-label mr-3" style={{ minWidth: '120px' }}>Webhook URL</label>
															  			<input 
																			type="text"
																			className={'form-control form-control-lg flex-grow-1' + (submitted && !formState.values.slack_webhook_url ? ' is-invalid' : '')} 
																			name="slack_webhook_url" 
																			onChange={handleChange} 
																			value={formState.values.slack_webhook_url || ''} 
															  			/>
																	</>
																: null}
													  	</div>
												  	</div>                              
												</div>
											</div>
										</div>
									: null}
								  

								  	{envirionment === 'STAGING' && isAnyRequested === false ?
								  		<div className="card" style={{ marginTop: "10px" }}>
  											<div className="card-body">
    											<h4 className="card-title">Integrations</h4>
    											<div className="row">
      												<div className="col-md-12">
        												<div className="form-group d-flex align-items-center justify-content-start">
          													<label 
            													className="col-form-label mr-3" 
            													style={{ fontSize: '1rem', fontWeight: 'bold', minWidth: '100px' }}
															>
            													Github -
          													</label>

          													<label 
            													className="col-form-label mr-2" 
            													style={{ fontSize: '1rem', fontWeight: 'bold', minWidth: '150px' }}
															>
            													Production YML - 
														
            													<i 
																	className="fa fa-eye"
														 			style={{"color": "#f2b622", "paddingLeft": "20px"}}
														 			onClick={() => {
																		setShowModal(true)
																		setYmlType("Production")
																	}}
																	title="View"
																></i>
          											
          														<i 
																	className="fa fa-download"
																	style={{"color": "#f2b622", "paddingLeft": "20px"}}
																	onClick={() => downloadYaml("production.yml", prod_yml_text)}
																	title="Download"
																></i>
          													</label>
          											
          													<label 
            													className="col-form-label mr-2" 
            													style={{ fontSize: '1rem', fontWeight: 'bold', minWidth: '150px', paddingLeft: "50px" }}
															>
            													Staging YML -
            													<i 
																	className="fa fa-eye"
																	style={{"color": "#f2b622", "paddingLeft": "20px"}}
																	onClick={() => {
																		setShowModal(true)
																		setYmlType("Staging")
																	}}
																	title="View"
																></i>
          											
            													<i 
																	className="fa fa-download"
																	style={{"color": "#f2b622", "paddingLeft": "20px"}}
																	onClick={() => downloadYaml("staging.yml", stage_yml_text)}
														 			title="Download"
																></i>
          													</label>
        												</div>
      												</div>
    											</div>
  											</div>

											<Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        										<Modal.Header closeButton>
          											<Modal.Title>{ymlType} YAML Configuration</Modal.Title>
        										</Modal.Header>
        										<Modal.Body>
          											<pre
            											style={{
              												background: "#f8f9fa",
              												padding: "10px",
              												borderRadius: "5px",
              												overflow: "auto",
            											}}
          											>
            											{ymlType === 'Production' ? 
		   													prod_yml_text
		    											: 
		  													stage_yml_text
		    											} 
          											</pre>
        										</Modal.Body>
        									<Modal.Footer>
          									<Button variant="secondary" onClick={() => setShowModal(false)}>
           										Close
          									</Button>
        								</Modal.Footer>
      								</Modal>

      
								  		</div>
								 	: null}



				                </div>
								<div className="text-center">
				                    <button onClick={()=> handleSubmit()} className="Button col-sm-2 col-form-label text-center mr-2">Submit</button>
                    				<Link to="/apps"><button className="ButtonLight col-sm-2 col-form-label text-center ml-2">Cancel</button></Link>
                    			</div>
				            </div>
			            </div>
				       <Footer/>
				    </div>
				</div>
			</div>
			{loading &&  <div className="fullscreen-loader">
          <Circles color="#F1B621" height={80} width={80} />
        </div>}
		</>
		)
}

export default EditApp;