import {
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_REGISTER_FAIL,
  VERIFY_CODE_FAIL,
  VERIFY_CODE_SUCCESS,
  RESEND_CODE_FAIL,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_REQUEST,
  SEND_CODE_FAIL,
  SEND_CODE_SUCCESS,
  SEND_CODE_REQUEST,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_SUCCESS_RESET,
  USER_LOGOUT,
  USER_CREATE_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_DROPDOWN,
  USER_EDIT_REQUEST,
  USER_EDIT_SUCCESS,
  USER_EDIT_FAIL
} from "../constants/UserContants";
import axios from "axios";
import { toast } from "react-toastify";
import { ToastObjects } from "./toastObject";
import { ORGANIZATION_DETAILS_RESET, ORGANIZATION_LIST_RESET } from "../constants/OrganizationConstants";
// Register

export const Register = (name, email, password) => async (dispatch) => {

  try {
    dispatch({ type: USER_REGISTER_REQUEST });    

    const { data } = await axios.post(
      `users/auth/register`,
      { name, email, password }
    );

    if (!data.success) {
      toast.error(data.message, ToastObjects);
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:data.message
      });
      return data;
    } else {
      toast.success(data.message, ToastObjects);
      const token = data.data.token
      dispatch({ 
        type: USER_REGISTER_SUCCESS, 
        payload:  {token}
      });
      return data;
    }
    
  } catch (error) {
    const message = error.message;
    toast.error(message, ToastObjects);
    dispatch({
      type: USER_REGISTER_FAIL,
      payload: message,
    });
  }
};

// ResendCode
export const ResendCode = (token) => async (dispatch) => {

  try {
    dispatch({ type: RESEND_CODE_REQUEST });    

    const { data } = await axios.post(
      `users/auth/resend/verification_code`,
      { token }
    );

    if (!data.success) {
      toast.error(data.message, ToastObjects);
      dispatch({
        type: RESEND_CODE_FAIL,
        payload:data.message
      });
      return data;
    } else {
      toast.success(data.message, ToastObjects);
      const token = data.data.token
      dispatch({ 
        type: RESEND_CODE_SUCCESS, 
        payload:  {token}
      });
      return data;
    }
    
  } catch (error) {
    const message = error.message;
    
    toast.error(message, ToastObjects);
    dispatch({
      type: RESEND_CODE_FAIL,
      payload: message,
    });
  }
};

// verify
export const Verify = (code, token) => async (dispatch) => {
  try { 
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };

    const { data } = await axios.post(
      `users/auth/verify`,
      { code },
      config
    );

    if (!data.success) {
      toast.error(data.message, ToastObjects);
      dispatch({
        type: VERIFY_CODE_FAIL,
      });
      return data;
    } else {
      toast.success(data.message, ToastObjects);
      dispatch({ type: VERIFY_CODE_SUCCESS});
      return data;
    }
    
  } catch (error) {
    const message = error.message;
    
    toast.error(message, ToastObjects);
  }
};

// SendVerification
export const sendCodeVerificationCode = (email) => async (dispatch) => {
  try {
    dispatch({ type: SEND_CODE_REQUEST });    

    const { data } = await axios.post(
      `users/auth/send/verification_code`,
      { email }
    );
    if (!data.success) {
      toast.error(data.message, ToastObjects);
      dispatch({
        type: SEND_CODE_FAIL,
        payload:data.message
      });
      return data;
    } else {
      toast.success(data.message, ToastObjects);
      dispatch({ 
        type: SEND_CODE_SUCCESS, 
      });
      return data;
    }
    
  } catch (error) {
    const message = error.message;
    
    toast.error(message, ToastObjects);
    dispatch({
      type: SEND_CODE_FAIL,
      payload: message,
    });
  }
};

export const ForgotPassword = (email) => async (dispatch) => {

  try { 
    const { data } = await axios.post(
      `users/auth/forgot-password`,
      { email }
    );

    if (!data.success) {
      toast.error(data.message, ToastObjects);
      dispatch({
        type: USER_REGISTER_FAIL,
      });
      return data;
    } else {
      toast.success(data.message, ToastObjects);
      dispatch({ type: USER_REGISTER_SUCCESS});
      return data;
    }
    
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    
    toast.error(message, ToastObjects);
  }
};

export const ChangePassword = (password, token) => async (dispatch) => {

  try { 
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };

    const { data } = await axios.post(
      `users/auth/change-password`,
      { password },
      config
    );

    if (!data.success) {
      toast.error(data.message, ToastObjects);
      dispatch({
        type: USER_REGISTER_FAIL,
      });
      return data;
    } else {
      toast.success(data.message, ToastObjects);
      dispatch({ type: USER_REGISTER_SUCCESS});
      return data;
    }
    
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    
    toast.error(message, ToastObjects);
  }
};

export const updatePassword = (current_password, new_password) => async (dispatch) => {
  try { 
    const { data } = await axios.post(
      `user/update/password`,
      { current_password, new_password },
    );

    if (!data.status) {
      toast.error(data.message, ToastObjects);
      return data;
    } else {
      toast.success(data.message, ToastObjects);
      dispatch(logout());
      return data;
    }
    
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    
    toast.error(message, ToastObjects);
  }
};

// Register Reset
export const RegisterReset = () => (dispatch) => {
  dispatch({ type: USER_REGISTER_SUCCESS_RESET });
};

// Login
export const Login = (email, password) => async (dispatch) => {
  
  const ToastObjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    autoClose: 2000,
    hideProgressBar: true,
  };
  
  try {
    dispatch({ type: USER_LOGIN_REQUEST });    

    const response = await axios.post(
      `users/auth/login`,
      { email, password }
    );    
  
    let responseData = response.data;  

    if (!responseData.success){
      // if(responseData.data && responseData.data.isAdmin === false){
      //   toast.error("You are not allowed to access admin.", ToastObjects);  
      // }else{
        toast.error(responseData.message, ToastObjects);
      //}
      
      dispatch({
        type: USER_LOGIN_FAIL,
      });
      return responseData;
    } else {
      dispatch({ type: USER_LOGIN_SUCCESS, payload: responseData });
      localStorage.setItem("userInfo", JSON.stringify(responseData));
      return responseData;
    }
  } catch (error) {
    const message = error.message;
    if (message === "Authentication failed: Invalid token." ||message === "Unauthorized User"||message === "Request failed with status code 401") {
      dispatch(logout());
    }

    if(message === "Request failed with status code 401"){
      dispatch(logout());
    }else{
      toast.error(message, ToastObjects);
    }
    
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: message,
    });
  }
};

// Logout
export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("token");

  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_LIST_RESET });
  dispatch({ type: ORGANIZATION_LIST_RESET });
  window.location.href = "/login"; 
};

// Get Users
export const listUser = (pageNum, userPerPage, filter, filterValue, searchText, role, from, organizationIds) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LIST_REQUEST });
    let url = ""

      if(role === 'Super_Admin'){
        if(from !== 'organization'){
          url = '/admin/users';
          const responseData = await axios.get(`${url}?page=${pageNum}&limit=${userPerPage}&filter=${filter}&filterValue=${filterValue}&searchText=${searchText}`);
          const data = responseData.data;
          data['numOfPages'] = Math.ceil(data.totalCount / userPerPage);
      
          dispatch({ type: USER_LIST_SUCCESS, payload: data });
        } else {
          if(organizationIds !== undefined && organizationIds !== null){
            url = `admin/users/${organizationIds}`
            const responseData = await axios.get(`${url}?page=${pageNum}&limit=${userPerPage}&filter=${filter}&filterValue=${filterValue}&searchText=${searchText}`);
            const data = responseData.data;
            data['numOfPages'] = Math.ceil(data.totalCount / userPerPage);
        
            dispatch({ type: USER_LIST_SUCCESS, payload: data });
          }
        }
      } else {
        if(organizationIds !== undefined && organizationIds !== null){
          url = `admin/users/${organizationIds}`;
          const responseData = await axios.get(`${url}?page=${pageNum}&limit=${userPerPage}&filter=${filter}&filterValue=${filterValue}&searchText=${searchText}`);
          const data = responseData.data;
          data['numOfPages'] = Math.ceil(data.totalCount / userPerPage);
      
          dispatch({ type: USER_LIST_SUCCESS, payload: data });
        }
      }
  } catch (error) {
    const message = error.message;
    if (message === "Authentication failed: Invalid token." || message ==="Unauthorized User" ||message === "Request failed with status code 401") {
      dispatch(logout());
    }

    if(message === "Request failed with status code 401"){
      dispatch(logout());
    }else{    
      toast.error(message)
    }

    dispatch({
      type: USER_LIST_FAIL,
      payload: message,
    });
  }
};

//Add user
export const addUser = ({reqData, organizationId}) => async (dispatch, getState) => {
      
  try {
    if(organizationId !== undefined && organizationId !== null){
      dispatch({ type: USER_CREATE_REQUEST });

      const response = await axios.post(
        `admin/user/add/${organizationId}`,
        reqData
      );
  
      const responseData = response.data;
      if (!responseData.status) {
        toast.error(responseData.message, ToastObjects);  
        return;
      }else{
        toast.success(responseData.message, ToastObjects);        
        dispatch({ type: USER_CREATE_SUCCESS, payload: responseData.data }); 
        return; 
      }
    }
    
  } catch (error) {
    const message = error.message;
    if (message === "Authentication failed: Invalid token." ||message === "Unauthorized User" || message === "Request failed with status code 401") {
      dispatch(logout());
    }

    if(message === "Request failed with status code 401"){
      dispatch(logout());
    }else{
      toast.error(message, ToastObjects)
    }

    dispatch({
      type: USER_CREATE_FAIL,
      payload: message,
    });
    return error;
  }
};

// Get Users Dropdown
export const UserDropdown = (page, id, organizationId) => async (dispatch, getState) => {
  try {
    if(organizationId !==  undefined && organizationId !== null){
      dispatch({ type: USER_LIST_REQUEST });
   
      const responseData = await axios.get(`/users/dd?from=${page}&appId=${id}&organizationId=${organizationId}`);
      const data = responseData.data;
  
      dispatch({ type: USER_DROPDOWN, payload: data });
    }

  } catch (error) {
    const message = error.message;
    if (message === "Authentication failed: Invalid token." ||message === "Unauthorized User" || message === "Request failed with status code 401") {
      dispatch(logout());
    }

    if(message === "Request failed with status code 401"){
      dispatch(logout());
    }else{
      toast.error(message)
    }

    dispatch({
      type: USER_DROPDOWN,
      payload: [],
    });
  }
};

// Get User
export const GetUser = ({id, organizationId}) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_EDIT_REQUEST });
   
    const responseData = await axios.get(`/admin/user/${id}?organizationId=${organizationId}`);
    const data = responseData.data;

    dispatch({ type: USER_EDIT_SUCCESS, payload: data });
  } catch (error) {
    const message = error.message;
    if (message === "Authentication failed: Invalid token." || message ==="Unauthorized User" || message === "Request failed with status code 401") {
      dispatch(logout());
    }

    if(message === "Request failed with status code 401"){
      dispatch(logout());
    }else{
      toast.error(message);
    }
    
    dispatch({
      type: USER_EDIT_FAIL,
      payload: {},
    });
  }
};

export const updateUser = ({reqData, orgId}) => async (dispatch) => {
  try { 
    const { data } = await axios.put(
      `admin/user/update?orgId=${orgId}`,
      reqData
    );

    if (!data.status) {
      toast.error(data.message, ToastObjects);
      return data;
    } else {
      toast.success(data.message, ToastObjects);
      return data;
    }
    
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    
    toast.error(message, ToastObjects);
  }
};


