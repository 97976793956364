import { Link } from "react-router-dom";
import { useState } from 'react';
import { useDispatch } from "react-redux";
import { Register } from "../../redux/actions/userActions";
import { Circles } from 'react-loader-spinner'; 

const RegisterPage = ({history}) =>{
  const [submitted, setSubmitted] = useState(false);  
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false); 

  const [formState,setFormState] = useState({
        values:{}       
  });
  
  const handleChange = (event) => {
    if(event.target.name === 'name'){
      const regex = /^[a-zA-Z0-9\s]*$/;
      if(regex.test(event.target.value)){
        setFormState(formState =>({
          ...formState,
          values:{
            ...formState.values,
            [event.target.name]:
            event.target.type === 'checkbox'
                ? event.target.checked
                : event.target.value
          }
        }));
      }
    } else {
      setFormState(formState =>({
        ...formState,
        values:{
          ...formState.values,
          [event.target.name]:
          event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        }
      }));
    }    
  }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true); 
        setLoading(true)
        const { name, email, password } = formState.values;
        if (name && email && password) {
          dispatch(Register(name, email, password)).then((response)=>{
            if(response?.data?.token){
              setLoading(false);
              history.push(`/Verify/${response?.data?.token}`);
            } else {
              setLoading(false);
            }
          });
        }
    }

    const togglePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
    };
    
	return(
        <>
          <div className="container-scroller loginInner">
            <div className="container page-body-wrapper full-page-wrapper">
              <div className="content-wrapper d-flex align-items-center auth px-0 loginInner">
                <div className="row w-100 mx-0 loginBg">
                  <div className="col-lg-5 ml-auto">
                    <div className="auth-form-light text-left py-3 px-4 px-sm-5 loginBox">
                      <div className="brand-logo">
                       <img src="assets/logo/Logo.svg" alt="logo" />
                      </div>
                      <h4 className="text-center mt-5">New here?</h4>
                      <h6 className="fw-normal text-center mt-3">Signing up is easy. It only takes a few steps</h6>
                      <form className="pt-3" onSubmit={handleSubmit} autoComplete="off">
                        <div className="form-group">
                        <label>Name <span className="required">*</span></label>
                          <input type="text" className={'form-control form-control-lg' + (submitted && !formState.values.name ? ' is-invalid' : '')} 
                                        name="name" 
                                        placeholder="Name"
                                        onChange={handleChange}
                                        value={formState.values.name || ''}
                                        />
                                        {submitted && !formState.values.name &&
                                            <div className="inline-errormsg">Name is required</div>
                                        }
                        </div>
                        <div className="form-group">
                        <label>Email <span className="required">*</span></label>
                          <input type="email" className={'form-control form-control-lg' + (submitted && !formState.values.email ? ' is-invalid' : '')} 
                                        name="email" 
                                        placeholder="Email"
                                        onChange={handleChange}
                                        value={formState.values.email || ''}
                                        />
                                        {submitted && !formState.values.email &&
                                            <div className="inline-errormsg">Email is required</div>
                                        }
                        </div>
                        <div className="form-group position-relative">
                        <label>Password <span className="required">*</span></label>
                          <input 
                            className={'form-control form-control-lg' + (submitted && !formState.values.password ? ' is-invalid' : '')} 
                            type={passwordVisible ? 'text' : 'password'}
                            name="password" 
                            placeholder="Password"
                            onChange={handleChange}
                            value={formState.values.password || ''}
                          />
                          <i
                            onClick={togglePasswordVisibility}
                            className={passwordVisible ? 'fa fa-eye-slash position-absolute' : 'fa fa-eye position-absolute'}
                            style={{ top: '70%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
                          ></i>

                          {submitted && !formState.values.password &&
                            <div className="inline-errormsg">Password is required</div>
                          }
                        </div>

                        <div className="mt-3">
                          <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn loginBtn" >SIGN UP</button>
                        </div>
                        <div className="my-2 d-flex justify-content-between align-items-center">
                        </div>                    
                        <div className="text-center mt-3 fw-normal">
                          Already have an account? <Link to="/login" className="createLink">Sign In</Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>          
            </div>        
          </div>
          {loading &&  <div className="fullscreen-loader">
          <Circles color="#F1B621" height={80} width={80} />
        </div>}
      </>
      )
}

export default RegisterPage;